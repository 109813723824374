.help-button {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  outline: none;
  border: none;
  margin: 2vw 0;
  padding: 6px 24px;
  h2 {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin: 0;
  }
}
