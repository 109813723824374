:root {
  --color-primary: #ff024a;
  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;

  --color-white: #ffffff;
  --color-black: #000000;

  --color-grey-dark: #a1a1a1;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-grey-light-2);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// text

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 122%;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 122%;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
a {
  color: inherit;
}

.underline {
  text-decoration-line: underline;
}

%flex-column {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app {
  display: flex;
  justify-content: center;
  margin: 0;
  height: 100%;
}

.content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  .logo {
    text-align: center;
    margin: 5vh 0 2vh 0;
  }
}

.loading-wrapper {
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--new-min-height {
    min-height: 130px;
  }
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

.hidden {
  display: none !important;
}

.error-message {
  color: red;
}

.text-center {
  text-align: center;
}

.campaign-modal {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  inset: 0;
  z-index: 1000;
  display: grid;
  place-items: center;
  background-color: var(--color-black);

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 40%;
    gap: 2rem;
    width: 98%;
    height: 40%;
    position: relative;
    z-index: 1;

    &--main {
      z-index: 1;
      color: var(--color-white);
      font-weight: 700;
      margin: 0;
    }

    &--background-image {
      position: absolute;
      inset: 0;
    }
  }
}

.custom-wrapper {
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
