.station-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  background-color: #000;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  flex-flow: column wrap;
  padding: 2rem 1.5rem;
  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }

  .container-top {
    box-sizing: border-box;
    justify-content: space-evenly;
    flex: 3;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    text-align: center;
  }

  .terms {
    margin: 0 0 3vw;
    text-align: center;
    color: #ffffff;
    a {
      text-decoration-line: underline;
      color: #ffffff;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &-data {
    display: flex;
    justify-content: space-between;
    &-image {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &-code {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  &-image {
    margin-top: 7vw;
    object-fit: cover;
    width: 70vw;
    height: 51vw;
    color: #ffffff;
  }
  &-description {
    color: #ffffff;
    font-size: 12px;
    line-height: 15px;
  }
  &-button {
    margin: 2vw 0;
    width: 100%;
  }
  .contact {
    color: #ffffff;
  }
}
