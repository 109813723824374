.signup-phone-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
  p {
    font-size: 14px;
    line-height: 21px;
    font-style: normal;
    font-weight: normal;
  }

  .text-container {
    width: 70%;
  }
  .top-container {
    display: flex;
    flex-direction: column;
    flex: 2;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    flex: 3;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &-data {
    display: flex;
    justify-content: space-between;
    &-image {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &-code {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  .station-info-image {
    margin-top: 7vw;
    object-fit: cover;
    width: 60vw;
    height: 51vw;
  }
  &-description {
    font-size: 12px;
    line-height: 15px;
  }
  .button-container {
    width: 70vw;
  }
}
