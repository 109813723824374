@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');

body,
html,
#root {
  margin: 0;
  height: 100%;
  width: 100vw;
  background-color: #f6f6f6;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Mon monospace;
}
.form-input + .form-input {
  margin-top: 15px;
}

p {
  white-space: pre-line;
}
