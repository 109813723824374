.button-with-loader {
  background: #000000;
  color: #FFFFFF;
  outline: none;
  border: none;
  padding: 15px;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:disabled {
    background: rgba(0, 0, 0, .7);
  }

  .action-loader-wrapper {
    position: absolute;
    top: 0; right: 0;
    display: flex;
    align-items: center;
    height: 100%;

    .action-loader {
      border: 4px solid #ffffff;
      border-radius: 50%;
      border-top: 4px solid #5f5c5c;
      width: 20px;
      height: 20px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin: 0 15px;
    }
  }



  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
