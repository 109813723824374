.start-free-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
  .container-top {
    box-sizing: border-box;
    justify-content: space-evenly;
    flex: 3;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container-bottom {
    box-sizing: border-box;
    flex: 3;
    text-align: left;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 10%;
    p {
      text-align: center;
    }

    .terms {
      margin: 0 0 3vw;
      text-align: start;
      a {
        text-decoration-line: underline;
        color: inherit;
      }
    }

    p:last-child {
      text-decoration-line: underline;
    }
  }

  .price-container {
    display: flex;
    align-items: center;
    h2 {
      color: #27ae60;
    }
    p {
      font-size: 15px;
    }
    .free {
      text-decoration-line: line-through;
      color: #6a6a6a;
    }
    span {
      font-weight: bold;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &-data {
    display: flex;
    justify-content: space-between;
    &-image {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &-code {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  &-image {
    margin-top: 7vw;
    object-fit: cover;
    width: 70vw;
    height: 51vw;
  }
  &-description {
    font-size: 12px;
    line-height: 15px;
  }
  &-button {
    margin: 2vw 0;
    width: 70%;
  }
}
