.update-user-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
  .top-container {
    display: flex;
    flex-direction: column;
    flex: 2;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    flex: 3;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &-data {
    display: flex;
    justify-content: space-between;
    &-image {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &-code {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  &-image {
    margin-top: 7vw;
    object-fit: cover;
    width: 70vw;
    height: 51vw;
  }
  &-description {
    font-size: 12px;
    line-height: 15px;
  }
  input {
    height: 47px;
    width: 68vw;
  }
  .button-container {
    width: 70vw;
  }
}
/*
export const PhoneNumberInput = styled(TextInput)`
  padding: 8px 4px;
  color: ${theme.background_variant.onColor};
  height: ${inputHeight}px;
  font-family: ${theme.fonts.MontserratBold};
  font-size: ${layout.isSmallDevice ? 14 : 16}px;
 */
