.confirm-code-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }
  .container-top {
    box-sizing: border-box;
    justify-content: space-evenly;
    flex: 3;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
  }
  .container-bottom {
    box-sizing: border-box;
    flex: 3;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0 10%;
    p {
      text-align: center;
    }

    .terms {
      margin: 0 0 3vw;
      text-align: start;
      a {
        text-decoration-line: underline;
        color: inherit;
      }
    }

    p:last-child {
      text-decoration-line: underline;
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  &-data {
    display: flex;
    justify-content: space-between;
    &-image {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &-code {
        color: #ffffff;
        background: #000000;
      }
    }
  }
  &-image {
    margin-top: 7vw;
    object-fit: cover;
    width: 70vw;
    height: 51vw;
  }
  &-description {
    font-size: 12px;
    line-height: 15px;
  }
  .button-container {
    width: 70vw;
  }
  .phone-number {
    font-weight: 500;
    margin: 2px;
  }
  p {
    line-height: 21px;
    font-size: 14px;
    margin: 2px;
  }
  .react-code-input {
    input {
      padding: 0;
    }
  }
}
/*
export const PhoneNumberInput = styled(TextInput)`
  padding: 8px 4px;
  color: ${theme.background_variant.onColor};
  height: ${inputHeight}px;
  font-family: ${theme.fonts.MontserratBold};
  font-size: ${layout.isSmallDevice ? 14 : 16}px;
 */
