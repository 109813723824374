.center {
  justify-content: center;
}
.margin-top {
  margin-top: 2rem;
}

.header-icons {
  max-height: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  flex: 1;
  z-index: 1;

  &--reverse {
    flex-direction: row-reverse;
  }
}

.campaign-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.4rem;
  box-sizing: border-box;
  width: 100%;
  padding: 3rem 1rem;
  position: relative;
  color: var(--color-white);
  max-width: 500px;
  margin: 0 auto;
  overflow-x: hidden;

  &--background-image {
    position: absolute;
    inset: 0;
  }

  @media (min-width: 390px) {
    gap: 1.09rem;
    padding: 2rem 1rem;
  }

  &--center {
    justify-content: center;
  }
}

.article {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1;
  text-align: center;
  font-weight: 400;
  margin: 0;
  color: var(--color-white);

  &__main {
    font-weight: 700;
    color: var(--color-white);
    margin: 0;
  }

  &__body {
    &--1 {
      font-weight: 400;
      margin: 0;
    }

    &--2 {
      margin: 0;
      margin-top: 2rem;
      font-weight: 700;
    }
  }
}

.form-wrapper {
  padding: 0.5rem;
  z-index: 1;

  .campaign-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__error {
      font-size: 10px;
      margin-top: 1rem;
      color: var(--color-white);
    }

    &__text-label {
      display: flex;
      flex-direction: column;
      padding-bottom: 3px;
      font-size: 10px;
      color: var(--color-grey-dark);
    }

    &__text-input {
      margin-top: 3px;
      padding-left: 5px;
      height: 53px;
      background: var(--color-white);
      border-radius: 0.25rem;
      font-size: 18px;

      &--error {
        margin-top: 3px;
        height: 53px;
        background: var(--color-white);
        border-radius: 0.25rem;
        font-size: 18px;
        border: 1px solid var(--color-primary);
      }
    }

    &__checkbox-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      color: var(--color-white);

      span {
        margin-right: auto;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      position: relative;
      border: 1px solid var(--color-white);
      width: 30px;
      height: 30px;
      background: transparent;
      transition: all 0.3s ease-in-out;
    }
    input[type='checkbox']:checked::before {
      content: '✓';
      position: absolute;
      bottom: 8px;
      left: 3px;
      font-size: xx-large;
      width: 25px;
      height: 25px;
      color: var(--color-primary);
    }
  }
}

.btn {
  display: grid;
  place-items: center;
  z-index: 1;
  height: 53px;
  font-size: 18px;
  border-radius: 0.25rem;
  background: var(--color-primary);
  outline: none;
  border: none;
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
}

.slider-wrapper {
  margin: 1rem 0rem;
  z-index: 1;
  &__main {
    text-align: center;
    color: var(--color-white);
    margin-bottom: 1.5rem;
  }

  .my-swiper {
    border-radius: 0.75rem;
    width: 21.375rem;
    height: 300px;
    background: var(--color-white);
    display: grid;
    place-items: center;
    section {
      display: grid;
      place-items: center;
      gap: 1.5rem;
      max-width: 14.5rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: var(--color-black);
    }

    swiper-pagination swiper-pagination-bullets,
    .swiper-pagination-horizontal {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-bottom: 0.5rem;
    }
    .swiper-pagination-bullet-active {
      background-color: var(--color-primary);
    }

    .heading {
      color: black;
      text-align: center;
      &--main {
        text-transform: uppercase;
        font-size: 1rem;
      }

      &--main,
      &--body {
        margin: 1rem 0rem;
      }
    }
  }
}
