@import 'src/App';

.result-completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  .description {
    font-size: 14px;
    line-height: 150%;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .top-container {
    display: flex;
    padding: 0 5%;
    flex: 4;
    flex-direction: column;
  }
  .mid-container {
    background-color: #ffffff;
    color: #000000;
    padding: 12px 1.5%;
    flex-direction: column;
    font-weight: bold;
    margin: 5%;
    border-radius: 12px;
  }
  .bottom-container {
    padding: 0 20%;
    display: flex;
    flex: 4;
    flex-direction: column;
    .contact-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-decoration-line: underline;
    }
  }
}

.result-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  background-color: #27ae60;
  color: #ffffff;
  h1 {
    font-size: 21px;
  }
  p {
    font-weight: 600;
    font-size: 15px;
  }
}

.result-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  background-color: #f2ad2d;
  color: #ffffff;
  h1 {
    margin-top: 7vh;
    font-size: 24px;
  }
  p {
    font-weight: 600;
    font-size: 15px;
  }
  img {
    width: 100vw;
  }
}

.result-failed-container {
  box-sizing: border-box;
  padding: 5% 12% 0;
  flex: 1;
  @extend %flex-column;
  .top-container {
    @extend %flex-column;
  }
  .mid-container {
  }
  .bot-container {
    @extend %flex-column;
    justify-content: flex-end;
    flex: 0.7;
  }
  h1 {
    margin: 16px 0;
  }
  p {
    text-align: center;
  }
}
