@import 'src/App';

.failed-screen-container {
  box-sizing: border-box;
  padding: 5% 12% 0;
  flex: 1;
  @extend %flex-column;
  .top-container {
    @extend %flex-column;
  }
  .mid-container {
    @extend %flex-column;
    flex: 1.25;
    justify-content: space-between;
  }
  .bot-container {
    @extend %flex-column;
    justify-content: flex-start;
    flex: 0.7;
  }
  h1 {
    margin: 16px 0;
  }
  p {
    text-align: center;
  }
}
